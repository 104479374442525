import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"

const getLongestLineWidth = function( svgContainer ) {

  //var line1Height= svgContainer.querySelector(".line-1").getBBox().height
  var line1Height = 18
  //var textBBox = textBox.getBBox()

  var lineElements = [".line-1", ".line-2", ".line-3"]
    .map(function(x){return svgContainer.querySelector(x)})

  var longestWidth = lineElements
    //.map(function(x){return x.getBBox().width})
    .map(function(x){return x.getComputedTextLength()})
  longestWidth = Math.max.apply(null, longestWidth)
  return longestWidth
  // console.log(longestWidth)
  //
  // lineElements.map(function(x) { x.setAttribute("dy", line1Height) })
  //
  // svgContainer.setAttribute("viewBox", "0 0 " + longestWidth + " " + (line1Height * 3 + 4.5))
}

const parseDomainText = (url) => {
  try {
    return (new URL(url)).host.replace(/www\./, "")
  } catch (ex) {
    return null
  }
}

const stopWords = new Set([
  "it", "its", "am", "is", "are", "was", "be", "has", "had", "do", "did", "a", "an", "the", "and", "but", "if", "or", "as", "of", "at", "by", "for", "with", "to", "from", "in", "then", "there", "so", "than", "too", "this", "they", "all", "that"
])

const acronyms = new Set([
  "MSNBC", "AOC", "USC", "DC", "DEA", "HRC", "CPAC", "LGBT", "LGBTQ", "GDP", "GOP", "CDC", "OMB", "DOJ", "DWI"
])

const titleCase= (str) => {

  return (str||"").replace(
    /\w\S*/g,
    function(txt) {
      if (acronyms.has(txt.toUpperCase().replace(/[\.\-]/g, ""))) {
        return txt.toUpperCase().replace(/[\.\-]/g, "")
      } else if (!stopWords.has(txt.toLowerCase())) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      } else {
        return txt.toLowerCase()
      }
    }
  ).replace(/^\S/, function(txt) {return txt.toUpperCase()})
    .trim()
    ;
}

// class Haiku extends React.Component {
//   constructor(props) {
//     super(props);
//     this.svgRef = React.createRef();
//     this.state = {longestLineWidth: 100};
//   }
//
//   componentDidMount() {
//
//     // console.log(this.svgRef)
//     // renderHaiku(this.svgRef.current)
//     this.setState({longestLineWidth: getLongestLineWidth(this.svgRef.current)})
//   }
//   componentDidUpdate(prevProps, prevState, snapshot) {
//     // renderHaiku(this.svgRef.current)
//   }
//
//   componentWillUnmount() {
//
//   }
//
//   render() {
//     const haiku= this.props.haiku || {content: ""};
//     const lines = haiku.content.split("\n")
//     const articleUrl = haiku['article-url']
//     const articleDescription = (haiku['description']||"").substr(0, 100) + "..."
//     const longestLineWidth = this.state.longestLineWidth
//
//     const dy = longestLineWidth * 0.27 * 0.3333
//     const viewboxWidth = longestLineWidth
//     const viewboxHeight = longestLineWidth * 0.27 + 5
//
//     return (
//       <div className="haiku">
//         <div>
//           <a href={haiku['article-url']}>
//             <svg className="haiku-svg" ref={this.svgRef} viewBox={`0 0 ${viewboxWidth} ${viewboxHeight}` }>
//               <text>
//                 <tspan className="line-1" alignmentBaseline="baseline" x="0" y={dy}>{titleCase(lines[0])}</tspan>
//                 <tspan className="line-2" alignmentBaseline="baseline" x="0" y={dy * 2}>{titleCase(lines[1])}</tspan>
//                 <tspan className="line-3" alignmentBaseline="baseline" x="0" y={dy * 3}>{titleCase(lines[2])}</tspan>
//               </text>
//             </svg>
//           </a>
//
//           <div className="article-body-preview">
//             {articleDescription}
//             &nbsp;&nbsp;
//             <span><a href={articleUrl}>{parseDomainText(articleUrl)}</a></span>
//           </div>
//         </div>
//
//
//       </div>
//     );
//   }
// }

const Haiku = (props) => {
  const haiku= props.haiku || {content: ""};
  const lines = haiku.content.split("\n")
  const svgRef = React.createRef();
  const articleUrl = haiku['article-url']
  const articleDescription = (haiku['description']||"").substr(0, 100) + "..."
  const [longestLineWidth, setLongestLineWidth] = useState(100)

  useEffect(()=> {
    setLongestLineWidth(getLongestLineWidth(svgRef.current))
  }, [svgRef.current, haiku.content])
  // const longestLineWidth = this.state.longestLineWidth

  const dy = longestLineWidth * 0.27 * 0.3333
  const viewboxWidth = longestLineWidth
  const viewboxHeight = longestLineWidth * 0.27 + 5

  return (
    <div className="haiku">
      <div>
        <a href={haiku['article-url']} target="_blank">
          <svg className="haiku-svg" ref={svgRef} viewBox={`0 0 ${viewboxWidth} ${viewboxHeight}` }>
            <text>
              <tspan className="line-1" alignmentBaseline="baseline" x="0" y={dy}>{titleCase(lines[0])}</tspan>
              <tspan className="line-2" alignmentBaseline="baseline" x="0" y={dy * 2}>{titleCase(lines[1])}</tspan>
              <tspan className="line-3" alignmentBaseline="baseline" x="0" y={dy * 3}>{titleCase(lines[2])}</tspan>
            </text>
          </svg>
        </a>

        <div className="article-body-preview">
          {articleDescription}
          &nbsp;&nbsp;
          <span><a href={articleUrl}>{parseDomainText(articleUrl)}</a></span>
        </div>
      </div>


    </div>
  );
}

export default Haiku
