import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Haiku from "../components/haiku"
import "./news-page.css"
import * as haikus from "../model/haikus"

const getPrevNextDates = (pageDates, pageDate) => {
  if ( !pageDates ) return null, null

  pageDates = pageDates.sort((l,r)=>(l.localeCompare(r)))

  const dateIndex = pageDates.indexOf(pageDate)
  const nextIndex = (dateIndex +1 < pageDates.length) ? dateIndex + 1 : null;
  const prevIndex = (0 <= dateIndex -1) ? dateIndex - 1 : null;
  return [prevIndex && pageDates[prevIndex], nextIndex && pageDates[nextIndex]]
}

const SecondPage = (props) =>{

  const pageDate = props.pageContext.pageDate
  const haikuData = props.pageContext.haikuData
  const pageDates = props.pageContext.pageDates

  const [prevPageDate,nextPageDate] = getPrevNextDates(pageDates, pageDate)

  return (
    <Layout>
      <h1></h1>
      <div className="row row-0">
        <Haiku haiku={haikuData[0]}/>
      </div>
      <div className="row row-1">
        {haikuData[1] &&
          <Haiku haiku={haikuData[1]}/>}
        {haikuData[2] &&
          <Haiku haiku={haikuData[2]}/>}
      </div>
      <div className="row row-2">
        {haikuData[3] &&
          <Haiku haiku={haikuData[3]}/>}
        {haikuData[4] &&
          <Haiku haiku={haikuData[4]}/>}
        {haikuData[5] &&
          <Haiku haiku={haikuData[5]}/>}
      </div>
      <div className="row row-3">
        {haikuData[6] &&
        <Haiku haiku={haikuData[6]}/>}
        {haikuData[7] &&
        <Haiku haiku={haikuData[7]}/>}
        {haikuData[8] &&
        <Haiku haiku={haikuData[8]}/>}
      </div>
      <div className="row row-4">
        {haikuData[9] &&
        <Haiku haiku={haikuData[9]}/>}
        {haikuData[10] &&
        <Haiku haiku={haikuData[10]}/>}
        {haikuData[11] &&
        <Haiku haiku={haikuData[11]}/>}
      </div>
      <div className="page-nav">
        {prevPageDate && <Link to={`/${prevPageDate}/`}>&lt; {prevPageDate}</Link>}
        &nbsp;|&nbsp;
        {nextPageDate && <Link to={`/${nextPageDate}/`}>{nextPageDate} &gt;</Link>}
      </div>

    </Layout>

  )
}

export default SecondPage
