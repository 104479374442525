const data = require("../../data/data")
// const superAgent = require('superagent');
// const https = require('https');
// const http = require('http');
//
// getJSON = (options, onResult) => {
//   console.log('rest::getJSON');
//   const port = options.port == 443 ? https : http;
//
//   let output = '';
//
//   const req = port.request(options, (res) => {
//     console.log(`${options.host} : ${res.statusCode}`);
//     res.setEncoding('utf8');
//
//     res.on('data', (chunk) => {
//       output += chunk;
//     });
//
//     res.on('end', () => {
//       let obj = JSON.parse(output);
//
//       onResult(res.statusCode, obj);
//     });
//   });
//
//   req.on('error', (err) => {
//     // res.send('error: ' + err.message);
//   });
//
//   req.end();
// };
//
const getData= () => {
  // return new Promise((resolve,reject) => {
  //   superAgent.get("https://storage.googleapis.com/haikunews-data-public/data.json")
  //     .accept("json")
  //     .then((resp)=> {
  //       console.log(resp)
  //       resolve(JSON.parse(resp.text))
  //     })
  //     .catch(console.error)
  // })
  //
  return Promise.resolve(data)
}

exports.getPageDates= () => {

  return getData()
    .then((data)=> {
      let output= data.map((article) => (article['ts-posted']))
        .filter(x=>x)
        .map(x => x.substr(0,10))
        .sort((l,r)=>(r.localeCompare(l)))
      output = [...new Set(output)]
      return output
    })
}


exports.getHaikusForDate= (date) => {

  return getData()
    .then((data)=> {
      let output = data.filter((x)=>((x['ts-posted']||"").startsWith(date)))
        .sort((l,r)=>(l['score'] - r['score']))
      return output
    })
}
